<template>
    <v-row>
      <v-col>
        <v-card flat>
          <v-toolbar flat height="46" class="transparent">
            <v-toolbar-title class="px-5 subtitle-2 grey-700--text font-weight-bold">Envío de LOGS</v-toolbar-title>
          </v-toolbar>
          <v-divider />
          <v-card-text class="px-5 py-4">
            <v-skeleton-loader v-if="loadingEmails" transition="fade-transition" type="list-item-three-line" />
            <v-list class="v-list-form-extended transparent pa-0" v-else>
              <v-list-item class="pa-0">
                <v-list-item-title>Envio de LOGS por email</v-list-item-title>
                <v-list-item-subtitle><v-switch class="mt-n4" v-model="disable_sending" color="green-500" dense style="position:absolute" :ripple="false" /></v-list-item-subtitle>
              </v-list-item>
              <v-list-item class="my-n4" v-for="(notification, i) in instance.emails" :key="notification">
                <v-list-item-title>Correo de notificación</v-list-item-title>
                <v-list-item-subtitle class="d-flex align-center">
                  <v-row align="center" no-gutters>
                    <div style="width: 180px;">
                      <span class="d-inline-block text-truncate" style="max-width: 180px;">{{notification}}</span>
                    </div>
                    <v-list-item-action>
                      <v-btn @click="instance.emails.splice(i, 1)" icon color="grey-500" :ripple="false"><v-icon>mdi-delete</v-icon></v-btn>
                    </v-list-item-action>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item>
              <v-list-item v-for="(d, i) in emailsList" :key="i" class="pa-0">
                <v-list-item-title>Email</v-list-item-title>
                <v-list-item-subtitle>
                  <v-row class="ma-0" no-gutters align="center">
                    <v-col class="py-1 pr-2" cols="8">
                      <v-text-field v-model.trim="$v.emailsList.$each[i].email.$model" :error="$v.emailsList.$each[i].email.$error" hide-details maxlength="254" outlined single-line dense />
                    </v-col>
                    <v-list-item-action class="ma-0">
                      <v-btn v-if="i >= 1 || emailsList.length > 1" @click="emailsList.splice(i, 1)" icon color="red" :ripple="false"><v-icon small>mdi-close</v-icon></v-btn>
                    </v-list-item-action>
                  </v-row>
                </v-list-item-subtitle>
              </v-list-item>
              <v-col class="pa-0 pt-1">
                <v-btn v-if="(emailsList.length + ((instance || {}).emails || []).length) < 8" @click.prevent="addRowEmails" text color="blue-500" :ripple="false"><v-icon x-small>mdi-plus</v-icon>
                  <span class="font-weight-medium body-1">Agregar otro email</span>
                </v-btn>
              </v-col>
            </v-list>
          </v-card-text>
          <v-divider />
          <v-card-actions class="px-5 py-4">
            <v-spacer />
            <v-btn class="px-4" @click="handlerCancel" outlined>Cancelar</v-btn>
            <v-btn class="px-4" @click="Object.prototype.hasOwnProperty.call(instance, 'xml_store_path') ? updatedEmails() : sendEmails()" color="blue-500" :loading="loading">Guardar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </template>
<script>
import { email, maxLength } from 'vuelidate/lib/validators'
import GenericViewMixin from '@/mixins/GenericViewMixin'
import cloneDeep from 'lodash/cloneDeep'
export default {
  mixins: [
    GenericViewMixin
  ],
  data: () => ({
    disable_sending: null,
    loadingEmails: false,
    loading: false,
    instance: [],
    emailsList: [{email: ''}]
  }),
  created () {
    this.getEmails()
  },
  methods: {
    getEmails () {
      this.loadingEmails = true
      this.$store.dispatch('pos/RETRIEVE', {
        resource: 'pos/settings',
        id: this.$store.state.auth.account.id
      })
      .then((response) => {
        this.disable_sending = response.data.disable_sending
        this.instance = cloneDeep(response.data)
      })
      .finally(() => {
        this.loadingEmails = false
      })
    },
    addRowEmails () {
      this.emailsList.push({email: ''})
    },
    sendEmails () {
      this.$v.emailsList.$touch()
      if (this.$v.emailsList.$invalid) {
        return false
      }
      let list = [...new Set([...this.emailsList.map((item) => item.email)])]
      this.loading = true
      this.$store.dispatch('pos/CREATE', {
        resource: `pos/settings`,
        payload: {
          emails: list.filter((e) => e.length),
          disable_sending: this.disable_sending ? 'True' : 'False'
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de LOG se realizó con éxito')
        list = []
        this.reset()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loading = false
      })
    },
    updatedEmails () {
      this.$v.emailsList.$touch()
      if (this.$v.emailsList.$invalid) {
        return false
      }
      this.loading = true
      let list = [...new Set([...this.instance.emails, ...this.emailsList?.map((item) => item.email)])]
      this.$store.dispatch('pos/UPDATE', {
        resource: `pos/settings`,
        id: this.$store.state.auth.account.id,
        payload: {
          emails: list.filter((e) => e?.length),
          disable_sending: this.disable_sending ? 'True' : 'False'
        }
      })
      .then(() => {
        this.$dialog.message.info('El envío de LOG se realizó con éxito')
        list = []
        this.reset()
      })
      .catch((error) => {
        this.$dialog.message.error(this.parseErrors(error.response.data))
      })
      .finally(() => {
        this.loading = false
      })
    },
    handlerCancel () {
      this.emailsList = [[{email: ''}]]
      if (this.instance.emails.length) this.getEmails()
    },
    reset () {
      this.emailsList = [[{email: ''}]]
      this.getEmails()
      this.$v.emailsList.$reset()
    }
  },
  validations: {
    emailsList: {
      $each: {
        email: {
          email,
          maxLength: maxLength(254)
        }
      }
    }
  }
}
</script>